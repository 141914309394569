import React, { useState } from 'react';
import styled from 'styled-components';
// import logo from '../../public/assets/Images/csDigitalLogo.png'
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { mobile } from '../responsive.js';
import CallIcon from '@mui/icons-material/Call';
import { Link } from 'react-router-dom';

function Navbar() {
  const handleCallNow = () => {
    window.location.href = 'tel: 98994 85091';
  };
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Wrapper>
      <LogoContainer>
        <Image src='/assets/Images/csDigitalLogo.png' alt="Logo" />
      </LogoContainer>
      <Hamburger onClick={toggleMenu}>
        {isOpen ? <CloseIcon style={{ fontSize: '28px', marginLeft: '-5px' }} /> : <MenuIcon style={{ fontSize: '28px' }} />}
      </Hamburger>
      <NavContainer isOpen={isOpen}>
        <NavLinks onClick={toggleMenu}><Link style={{ textDecoration: 'none', color: 'inherit' }} to='/'>Home</Link></NavLinks>
        <NavLinks onClick={toggleMenu}><Link style={{ textDecoration: 'none', color: 'inherit' }} to='/about'>About Us</Link></NavLinks>
        <NavLinks onClick={toggleMenu}>Services</NavLinks>
        <NavLinks onClick={toggleMenu}>Contact</NavLinks>
      </NavContainer>
      <RightContainer>
        <CallButton onClick={handleCallNow}><CallIcon /> 98994 85091</CallButton>
      </RightContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #fff;
  display: flex;
  align-items: center;
  height: 70px;
  position: fixed; /* Make navbar fixed at top */
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1001;
  padding: 0 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  justify-content: space-between;

  @media (max-width: 768px) {
    padding: 0 10px;
  }
`;

const LogoContainer = styled.div`
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  ${mobile({ justifyContent: "flex-start", flex: '1' })}
`;

const NavContainer = styled.div`
  flex: 4;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: transform 0.3s ease-in-out;

  @media (max-width: 768px) {
    position: absolute;
    top: 70px; /* Adjust to the height of the navbar */
    left: 0;
    width: 100%;
    height: calc(100vh - 70px);
    background-color: #fff;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0;
    transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(-100%)')};
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  ${mobile({ flex: '0' })}
`;

const RightContainer = styled.div`
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  ${mobile({ justifyContent: "flex-start", flex: '6' })}
`;

const CallButton = styled.button`
  background-color: #1992cb;
  color: #fff;
  font-size: 18px;
  width: 170px;
  height: 45px;
  border: none;
  border-radius: 13px;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;

  &:hover {
    background-color: #156d96;
  }
  ${mobile({ justifyContent: "flex-start", width: '170px', height: '40px', marginLeft: "10px" })}
`;

const Hamburger = styled.div`
  display: none;
  cursor: pointer;

  @media (max-width: 768px) {
    z-index: 1001;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    right: 30px;
    top: 19px;
  }
`;

const NavLinks = styled.span`
  text-align: center;
  cursor: pointer;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 550;
  transition: color 0.3s ease;

  &:hover {
    color: #1992cb;
  }

  @media (max-width: 768px) {
    padding: 10px 0;
    width: 100%;
    text-align: left;
    padding-left: 20px;
    margin: 5px 0;
  }
`;

const Image = styled.img`
  max-width: 100px;
  height: 50px;
  ${mobile({ maxWidth: "60px", height: "30px" })}
`;

export default Navbar;
