import React, { useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import emailjs from '@emailjs/browser';
import { mobile } from '../responsive';

function ContactUs() {
  const form = useRef();
  const [formState, setFormState] = useState({
    name: '',
    phone: '',
    email: '',
    subject: '',
    message: '',
  });
  const [notification, setNotification] = useState({ visible: false, message: '', success: true });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(process.env.REACT_APP_SERVICE_KEY, process.env.REACT_APP_TEMPLATE_KEY, form.current, {
        publicKey: process.env.REACT_APP_PUBLIC_KEY,
      })
      .then(
        () => {
          console.log('SUCCESS!');
          form.current.reset(); // Reset the form after successful submission
          setFormState({
            name: '',
            phone: '',
            email: '',
            subject: '',
            message: '',
          });
          showNotification('Message Sent Successfully!', true);
        },
        (error) => {
          console.log('FAILED...', error.text);
          showNotification('Message Sending Failed.', false);
        },
      );
  };

  const showNotification = (message, success) => {
    setNotification({ visible: true, message, success });
    setTimeout(() => {
      hideNotification();
    }, 4000);
  };

  const hideNotification = () => {
    setNotification((prev) => ({ ...prev, visible: false }));
  };

  return (
    <Wrapper>
      <Heading>
        <span style={{ color: "#1992cb" }}>Let's</span> <span>Talk</span>
      </Heading>
      <Form ref={form} onSubmit={handleSubmit}>
        <Personal>
          <Left>
            <Contain>
              <Input
                name="name"
                type="text"
                placeholder="Your Name"
                value={formState.name}
                onChange={handleChange}
                required
              />
            </Contain>
            <Contain>
              <Input
                name="phone"
                type="text"
                placeholder="Your Phone"
                value={formState.phone}
                onChange={handleChange}
                required
              />
            </Contain>
          </Left>
          <Right>
            <Contain>
              <Input
                name="email"
                type="email"
                placeholder="Your Email"
                value={formState.email}
                onChange={handleChange}
                required
              />
            </Contain>
            <Contain>
              <Input
                name="subject"
                type="text"
                placeholder="Your Subject"
                value={formState.subject}
                onChange={handleChange}
                required
              />
            </Contain>
          </Right>
        </Personal>
        <Message>
          <Cont>
            <Textarea
              name="message"
              placeholder="Your Message"
              value={formState.message}
              onChange={handleChange}
              required
            />
          </Cont>
        </Message>
        <ButtonContainer>
          <Button type="submit">Send Message</Button>
        </ButtonContainer>
      </Form>
      {notification.visible && (
        <Notification success={notification.success}>
          {notification.message}
        </Notification>
      )}
    </Wrapper>
  );
}

const fadeInOut = keyframes`
  0% { opacity: 0; }
  10% { opacity: 1; }
  90% { opacity: 1; }
  100% { opacity: 0; }
`;

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  margin-top: 70px;
  margin-bottom: 20px;
  ${mobile({ marginTop: '20px', marginBottom: '10px' })}
`;

const Heading = styled.h1`
  margin: 0px auto 40px auto;
  width: 75%;
  font-size: 40px;
  text-align: center;
  ${mobile({ width: '90%', fontSize: '24px' })}
`;

const Form = styled.form`
  width: 70%;
  height: auto;
  margin: auto;
  display: flex;
  flex-direction: column;
  ${mobile({ width: '90%' })}
`;

const Personal = styled.div`
  width: 100%;
  height: 150px;
  display: flex;
  ${mobile({ flexDirection: 'column', height: 'auto' })}
`;

const Contain = styled.div`
  width: 80%;
  height: auto;
  background-color: #1992cb;
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-radius: 3px;
  box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.22);
  -webkit-box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.22);
  -moz-box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.22);
  ${mobile({ width: '100%', marginTop: '10px' })}
`;

const Input = styled.input`
  width: 99%;
  height: 50px;
  outline: none;
  border: none;
  padding: 0 10px;
  box-sizing: border-box;
`;

const Left = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${mobile({ width: '100%' })}
`;

const Right = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  ${mobile({ width: '100%' })}
`;

const Message = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Cont = styled.div`
  width: 100%;
  height: auto;
  background-color: #1992cb;
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-radius: 3px;
  box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.22);
  -webkit-box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.22);
  -moz-box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.22);
  ${mobile({ width: '100%', marginTop: '10px' })}
`;

const Textarea = styled.textarea`
  width: 99%;
  height: 150px;
  outline: none;
  border: none;
  resize: none;
  font-size: 15px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const Button = styled.button`
  width: 200px;
  height: 50px;
  background-color: #1992cb;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  outline: none;
  font-size: 16px;
  transition: background-color 0.3s ease;

  &:active {
  background-color: #156b8a;
  box-shadow: 0px 0px #156b8a;

  ${mobile({ width: '100%' })}
`;

const Notification = styled.div`
  width: 300px;
  margin: 20px auto;
  padding: 10px;
  text-align: center;
  background-color: ${(props) => (props.success ? '#4caf50' : '#f44336')};
  color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.22);
  position: relative;
  top: -20px;
  animation: ${fadeInOut} 4s ease-in-out forwards;
  ${mobile({ width: '90%' })}
`;

export default ContactUs;
