import React from 'react'
// import style from 'styled-components'
import Banner from '../Components/Banner'
import Services from '../Components/Services/Services'
import Choose from '../Components/Choose'
import Stats from '../Components/Stats'
import Testimonials from '../Components/Testimonials/Testimonials'
// import Footer from '../Components/Footer'
import ContactUs from '../Components/ContactUs'
function Home() {
  return (
    <>
        <Banner/>
        <Services/>
        <Choose/>
        <Stats/>
        <ContactUs/>
        <Testimonials/>
        {/* <Footer/> */}
    </>

  )
}

export default Home
