import React from 'react';
import styled from 'styled-components';
// import Ishant from '../../public/assets/Images/Ishant.jpg';
// import Jatin from '../../public/assets/Images/Jatin.jpg';

function About() {
  return (
    <Wrapper>
      <AboutContainer>
        <Para>
          Welcome to CS DigitalWave – your dedicated partner in navigating the digital landscape. Founded in 2024, we specialize in providing businesses of all sizes with top-notch digital marketing solutions, including SEO, SMO, Google Ads, Website Development, and Content Writing. Our mission is simple: to deliver reliable, results-driven services that boost your online presence and drive growth, all at a reasonable price.
        </Para>
      </AboutContainer>
      <OurTeamHeading>Our Team</OurTeamHeading>
      <ImageContainer>
        <Image>
          <Photo src='/assets/Images/Ishant.jpg' alt="Ishant Garg" />
          <Text>Ishant Garg - CEO</Text>
        </Image>
        <Image>
          <Photo src='/assets/Images/Jatin.jpg' alt="Jatin Bhardwaj" />
          <Text>Jatin Bhardwaj - CTO</Text>
        </Image>
      </ImageContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  padding-top: 70px;  /* Added top padding */
  //   @media (max-width: 768px) {
  //   width: 90%;
  //   padding: 20px 0;
  // }
`;

const AboutContainer = styled.div`
  width: 80%;
  margin: auto;
  padding: 30px 0;
  box-sizing: border-box;

  @media (max-width: 768px) {
    width: 90%;
    padding: 20px 0;
  }
`;

const Para = styled.p`
  padding: 30px;
  border-radius: 5px;
  box-sizing: border-box;
  line-height: 25px;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.2);
`;

const OurTeamHeading = styled.h2`
  text-align: center;
  margin: 20px auto;
  font-size: 28px;
  font-weight: bold;
  color: #333;

  @media (max-width: 768px) {
    font-size: 24px;
    margin: 15px auto;
  }
`;

const ImageContainer = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin: auto;
  padding: 30px 0;
  box-sizing: border-box;

  @media (max-width: 768px) {
    width: 90%;
    flex-direction: column;
    gap: 15px;
  }
`;

const Image = styled.div`
  width: 48%;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.2);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  border-radius: 8px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Photo = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-bottom: 10px;
  object-fit: cover;
`;

const Text = styled.div`
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #333;
`;

export default About;
