
// import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from "./Components/Navbar";
import Home from "./Pages/Home";
import About from './Pages/About';
import Footer from './Components/Footer';

function App() {
  return (
    <>
    
    <Router>
      <Navbar/>
      <Routes>
        <Route path ='/' element={<Home/>}/>
        <Route path='/about' element={<About/>}/>
      </Routes>
      <Footer/>
    </Router>
    
    </>
  );
}

export default App;
