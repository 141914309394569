import React from 'react';
import styled from 'styled-components';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';

function Footer() {
  const url = "https://maps.app.goo.gl/UEkN6pnVyvvX7L4d6";
  return (
    <Wrapper>
      <Container>
        <InfoContainer>
          <h3>About Us</h3>
          <p>CSdigitalWave - Best Digital Marketing Company in Gurgaon, we believe in the power of digital transformation.</p>
          <p>&copy; CSdigitalWave 2024</p>
          <Links>
            <IconLink href="https://www.instagram.com/cs_.digitalwave/profilecard/?igsh=MTRibjUwZnM5amhnbw==" target='_blank' rel="noreferrer">
              <InstagramIcon fontSize="large" />
            </IconLink>
            <IconLink href="https://x.com/CSdigital601?t=u48I0UO3o6TVApDa_cr4Sw&s=09" target='_blank' rel="noreferrer">
              <TwitterIcon fontSize="large" />
            </IconLink>
            <IconLink href="https://www.linkedin.com/company/chamaksaathi-digitalwave/" target='_blank' rel="noreferrer">
              <LinkedInIcon fontSize="large" />
            </IconLink>
            <IconLink href="https://www.facebook.com/csdigitalwave?mibextid=ZbWKwL" target='_blank' rel="noreferrer">
              <FacebookIcon fontSize="large" />
            </IconLink>
          </Links>
        </InfoContainer>
        <InformationContainer>
          <h3>Our Services</h3>
          <p>SEO Service</p>
          <p>SMM Service</p>
          <p>Content Writing</p>
          <p>Website Development</p>
          <p>Google Ads</p>
        </InformationContainer>
        <InformationContainer>
          <h3>Useful Links</h3>
          <p>About Us</p>
          <p>Contact Us</p>
          <p>Privacy Policy</p>
          <p>Terms & Conditions</p>
        </InformationContainer>
        <InformationContainer>
          <h3>Contact Info</h3>
          <p>Phone: 9818102998</p>
          <p>Email: csdigiital@gmail.com</p>
          <Address>
            <p>Address: SVH Metro Street, Sector-83, Gurgaon(Haryana)</p>
            <a style={{ textDecoration: "none", color: "#fff" }} target='_blank' rel="noreferrer" href={url}>
              <OpenInNewIcon />
            </a>
          </Address>
        </InformationContainer>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  background-color: #000;
  padding: 40px 0;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px; /* Reduce gap to make InfoContainers closer */
  width: 80%;
  margin: auto;
  color: #fff;
  box-sizing: border-box;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    width: 90%;
    text-align: center;
    gap: 5px; /* Smaller gap for mobile view */
  }
`;


const InfoContainer = styled.div`
  flex: 1 1 250px;
  min-width: 250px;
  box-sizing: border-box;

  h3 {
    margin-bottom: 15px;
  }

  p {
    margin: 5px 0;
  }
`;

const InformationContainer = styled.div`
  flex: 1 1 250px;
  min-width: 250px;
  box-sizing: border-box;

  h3 {
    margin-bottom: 15px;
  }

  p {
    margin: 5px 0;
  }
`;

const Links = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  margin-top: 15px;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const IconLink = styled.a`
  color: #fff;
  font-size: 2rem;

  &:hover {
    color: #ff6347; /* Example hover color */
  }
`;

const Address = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;  /* Reduce gap to bring icon closer */
  margin-top: 5px;

  @media (max-width: 768px) {
    justify-content: center;
  }

  a {
    display: flex;
    align-items: center;
    margin-left: 3px; /* Adjust as needed for closer spacing */
  }
`;


export default Footer;
