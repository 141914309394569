import React from 'react';
import styled from 'styled-components';
import Card from './Card';
// import dev from '../../../public/assets/Images/dev.jpg'
// import googlead from '../../../public/assets/Images/googlead.jpg'
// import seo from '../../../public/assets/Images/seo.jpg';
// import smo from '../../../public/assets/Images/smm.jpg';
// import content from '../../../public/assets/Images/content.jpg';
import { mobile } from '../../responsive';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './service.css';
import { Autoplay, Pagination } from 'swiper/modules';

const servicesContent = [
  {
    image: "/assets/Images/seo.jpg", // No import needed, just a relative path
    heading: "SEO Services",
    paragraph: "By utilizing the latest white hat practices, using the best SEO tools available, and performing in-depth keyword research, we can help you steal the top initial positions for your website in SERPs."
  },
  {
    image: "/assets/Images/smo.jfif",
    heading: "SMM Services",
    paragraph: "In today’s digital realm, businesses that overlook the power of social media may not perform well. Thus, we help you stay connected to your target audience on social media & keep them engaged."
  },
  {
    image: "/assets/Images/content.jpg",
    heading: "Content Writing",
    paragraph: "Our content writers understand the intricacies of Content Writing as well as SEO. We specialize in creating informative, compelling, and interactive content copies that clearly articulate business objectives."
  },
  {
    image: "/assets/Images/dev.jpg",
    heading: "Website Development",
    paragraph: "Operational as a Web Design & Development Company in India, we create websites that are easier to navigate and give a better user experience to the viewers, focusing on innovation and quality."
  },
  {
    image: "/assets/Images/googlead.jpg",
    heading: "Google Ads",
    paragraph: "Our Google Ads services ensure that your advertisements are strategically placed at the top of search results, targeting the right audience precisely and maximizing your ROI efficiently."
  }
];


function Services() {
  return (
    <Wrapper>
      <Heading>
        <h1>Our <span style={{ color: "#1992cb" }}>Services</span></h1>
      </Heading>
      <Swiper
        slidesPerView={3}
        spaceBetween={80}
        loop={true}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          200: {
            slidesPerView: 1,
            spaceBetween: 20,
            centeredSlides: true,
          },
          382: {
            slidesPerView: 1,
            spaceBetween: 20,
            centeredSlides: true,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
            centeredSlides: true,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 40,
            centeredSlides: true,
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 50,
          },
          1110:{
            slidesPerView:3,
            spaceBetween:50
          },
        }}
        modules={[Autoplay, Pagination]}
        className="swiperr"
      >
        {servicesContent.map((item, index) => (
          <SwiperSlide key={index} className='swiperSlide'>
            <Card heading={item.heading} para={item.paragraph} image={item.image} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: 90vh;
  padding: 50px 0;
  background-color: #f9f9f9;
  ${mobile({paddingTop:"0"})}
`;

const Heading = styled.div`
  text-align: center;
  margin-bottom: 30px;
`;

export default Services;
