import React from 'react'
import styled from 'styled-components'
import { mobile } from '../responsive'

function Stats() {
  return (
    <Container>
      <Stat>
        <StatNumber>50+</StatNumber>
        <StatText>Verified Reviews</StatText>
      </Stat>
      <Stat>
        <StatNumber>10+</StatNumber>
        <StatText>Active Projects</StatText>
      </Stat>
      <Stat>
        <StatNumber>50+</StatNumber>
        <StatText>Team Members</StatText>
      </Stat>
      <Stat>
        <StatNumber>20+</StatNumber>
        <StatText>Completed Projects</StatText>
      </Stat>
    </Container>
  )
}

const Container = styled.div`
  margin-top: 40px;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-around;
  background-color: #1992cb;
  opacity: .9;
  margin-bottom: 40px;
  flex-wrap: wrap;

  ${mobile({
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  })}
`

const Stat = styled.div`
  height: 200px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${mobile({
    height: '150px',
  })}
`

const StatNumber = styled.p`
  font-size: 60px;
  font-weight: 600;
  margin: 0;
  color: #fff;

  ${mobile({
    fontSize: '40px',
  })}
`

const StatText = styled.p`
  font-size: 25px;
  margin: 0;
  color: #fff;

  ${mobile({
    fontSize: '20px',
  })}
`

export default Stats
